<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('admin.filters.title') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-1"
        >
          <label for="status">{{ $t('form.status.label') }}</label>
          <v-select
            id="status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="8"
          class="mb-1"
        >
          <label for="stock_status">{{ $t('form.stock_status.label') }}</label>
          <v-select
            id="stock_status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="stockStatusFilter"
            :options="statuses"
            :multiple="true"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:stockStatusFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-1"
        >
          <label for="indexing_status">{{ $t('form.indexing_status.label') }}</label>
          <v-select
            id="indexing_status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="indexingStatusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:indexingStatusFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="8"
          class="mb-1"
        >
          <label for="category">{{ $t('admin.table.fields.category') }}</label>
          <v-select
            id="category"
            :value="categoriesFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="categories"
            :reduce="val => val.value"
            :clearable="true"
            :multiple="true"
            input-id="category"
            @input="(value) => $emit('update:categoriesFilter', value)"
          />
        </b-col>

        <b-col
          cols="12"
          md="9"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <label for="category">{{ $t('admin.table.fields.price') }}</label>
              <b-row>
                <b-col
                  cols="6"
                  class="mb-1"
                >
                  <b-form-input
                    :value="priceMin"
                    :placeholder="'from'"
                    type="number"
                    step="1"
                    min="0"
                    max="999999"
                    @input="(val) => $emit('update:priceMin', val)"
                  />
                </b-col>
                <b-col
                  cols="6"
                  class="mb-1"
                >
                  <b-form-input
                    :value="priceMax"
                    :placeholder="'to'"
                    type="number"
                    step="1"
                    min="0"
                    max="999999"
                    @input="(val) => $emit('update:priceMax', val)"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <label for="category">{{ $t('admin.table.fields.quantity') }}</label>
              <b-row>
                <b-col
                  cols="6"
                  class="mb-1"
                >
                  <b-form-input
                    :value="qntMin"
                    :placeholder="'from'"
                    type="number"
                    step="1"
                    min="0"
                    max="999999"
                    @input="(val) => $emit('update:qntMin', val)"
                  />
                </b-col>
                <b-col
                  cols="6"
                  class="mb-1"
                >
                  <b-form-input
                    :value="qntMax"
                    :placeholder="'to'"
                    type="number"
                    step="1"
                    min="0"
                    max="999999"
                    @input="(val) => $emit('update:qntMax', val)"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="9"
          md="2"
        >
          <label>.</label>
          <b-button
            variant="primary"
            block
            @click="$emit('refetch')"
          >
            <span class="text-nowrap">{{ $t('general.filter') }}</span>
          </b-button>
        </b-col>
        <b-col
          cols="3"
          md="1"
        >
          <label>.</label>
          <b-button
            variant="warning"
            block
            @click="clear"
          >
            <span class="text-nowrap">X</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormInput,
} from 'bootstrap-vue'
import CategoryMixin from '@/mixins/CategoryMixin'
import SweetAlertMixin from '@/mixins/SweetAlertMixin'
import vSelect from 'vue-select'
import StatusMixin from '@/mixins/StatusMixin'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  mixins: [CategoryMixin, StatusMixin, SweetAlertMixin],
  props: {
    categoriesFilter: {
      type: [Array, null],
      default: null,
    },
    stockStatusFilter: {
      type: [Array, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    indexingStatusFilter: {
      type: [String, null],
      default: null,
    },
    priceMin: {
      type: [Number, String, null],
      default: null,
    },
    priceMax: {
      type: [Number, String, null],
      default: null,
    },
    qntMin: {
      type: [Number, String, null],
      default: null,
    },
    qntMax: {
      type: [Number, String, null],
      default: null,
    },
    statuses: {
      type: [Array],
      default: () => [],
    },
    categories: {
      type: [Array],
      default: () => [],
    },
  },
  methods: {
    clear() {
      this.$emit('update:categoriesFilter', null)
      this.$emit('update:brandsFilter', null)
      this.$emit('update:stockStatusFilter', null)
      this.$emit('update:statusFilter', null)
      this.$emit('update:indexingStatusFilter', null)
      this.$emit('update:needManageFilter', null)
      this.$emit('update:priceMin', null)
      this.$emit('update:priceMax', null)
      this.$emit('update:qntMin', null)
      this.$emit('update:qntMax', null)
      const self = this
      setTimeout(() => {
        self.$emit('refetch')
      }, 100)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
