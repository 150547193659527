import {
  ref, watch, computed, onMounted,
} from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  const perPage = ref(25)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(null)
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const typeFilter = ref('service')
  const statusFilter = ref(null)
  const stockStatusFilter = ref(null)
  const indexingStatusFilter = ref(null)
  const needManageFilter = ref(null)
  const categoriesFilter = ref(null)
  const brandsFilter = ref(null)
  const priceMin = ref(null)
  const priceMax = ref(null)
  const qntMin = ref(null)
  const qntMax = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  const saveFiltersToStorage = filters => {
    localStorage.setItem('serviceFilters', JSON.stringify(filters))
  }

  const getFiltersFromStorage = () => {
    const storedFilters = localStorage.getItem('serviceFilters')
    return storedFilters ? JSON.parse(storedFilters) : null
  }

  watch([
    currentPage,
    perPage,
    searchQuery,
  ], e => {
    if (e[0] && e[1]) {
      refetchData()
    }
  })

  watch([
    currentPage,
    perPage,
    searchQuery,
    categoriesFilter,
    brandsFilter,
    statusFilter,
    stockStatusFilter,
    needManageFilter,
    indexingStatusFilter,
    priceMin,
    priceMax,
    qntMin,
    qntMax,
  ], () => {
    saveFiltersToStorage({
      query: searchQuery.value,
      per_page: perPage.value,
      page: currentPage.value,
      categories: categoriesFilter.value,
      brands: brandsFilter.value,
      status: statusFilter.value,
      stockStatus: stockStatusFilter.value,
      needManage: needManageFilter.value,
      indexingStatus: indexingStatusFilter.value,
      priceMin: priceMin.value,
      priceMax: priceMax.value,
      qntMin: qntMin.value,
      qntMax: qntMax.value,
    })
  }, { deep: true })

  const fetchData = (ctx, callback) => {
    store
      .dispatch('products/paginate', {
        type: typeFilter.value,
        query: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        sort_by: sortBy.value,
        sort_desc: isSortDirDesc.value,
        categories: categoriesFilter.value,
        attributes: brandsFilter.value,
        status: statusFilter.value,
        stock_statuses: stockStatusFilter.value,
        need_manage: needManageFilter.value,
        indexing_status: indexingStatusFilter.value,
        price_min: priceMin.value,
        price_max: priceMax.value,
        qnt_min: qntMin.value,
        qnt_max: qntMax.value,
      })
      .then(response => {
        const { data, meta: { total } } = response.data

        callback(data)
        totalItems.value = total
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  onMounted(() => {
    const storedFilters = getFiltersFromStorage()
    if (storedFilters) {
      currentPage.value = storedFilters.page || 1
      perPage.value = storedFilters.per_page || 25
      searchQuery.value = storedFilters.query || null
      categoriesFilter.value = storedFilters.categories || null
      brandsFilter.value = storedFilters.brands || null
      statusFilter.value = storedFilters.status || null
      stockStatusFilter.value = storedFilters.stockStatus || null
      needManageFilter.value = storedFilters.needManage || null
      indexingStatusFilter.value = storedFilters.indexingStatus || null
      priceMin.value = storedFilters.priceMin || null
      priceMax.value = storedFilters.priceMax || null
      qntMin.value = storedFilters.qntMin || null
      qntMax.value = storedFilters.qntMax || null
      refetchData()
    }
  })

  return {
    fetchData,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
    categoriesFilter,
    brandsFilter,
    statusFilter,
    stockStatusFilter,
    needManageFilter,
    indexingStatusFilter,
    priceMin,
    priceMax,
    qntMin,
    qntMax,
  }
}
